<template>
    <div class="single-question-content">
        <div class="question-and-answer">
            <div class="question-title">
                <div class="question-title-font">
                    <span>单选</span>
                    <div class="sub-title">{{ questionIndex }}.{{ resultData.questionText }}</div>
                </div>
                <div v-if="resultData.remark" class="remark">{{ resultData.remark }}</div>
            </div>
            <div class="answer-list">
                <div class="answer-list-question">
                    <div
                        class="single-answer-list-li"
                        v-for="(item, index) in resultData.answerList"
                        :key="index"
                        v-show="item.display == 'true'"
                    >
                        <div :class="item.checked ? 'answer-list-li-active' : 'answer-list-li'">
                            <a-radio
                                :style="radioStyle"
                                :checked="item.checked"
                                :value="item.answerId"
                                :disabled="fanye"
                                @change="onChange"
                            >
                                <span class="answerText">{{ item.answerText }}</span>
                            </a-radio>
                        </div>
                        <a-popover placement="right" v-if="item.remark">
                            <template slot="content">
                                <div>{{ item.remark }}</div>
                            </template>
                            <div class="tishi-wall">?</div>
                        </a-popover>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SingleQuestion',
        props: ['resultData', 'questionIndex', 'fanye'],
        data() {
            return {
                value: '1',
                radioStyle: {
                    display: 'block',
                    color: '#333333',
                },
            }
        },
        // mounted() {
        //   this.divScroll()
        // },
        // watch:{
        //   fanye(){
        //     setTimeout(()=>{
        //       let testDiv = document.getElementsByClassName('answer-list')[document.getElementsByClassName('answer-list').length-1]
        //       let container = document.getElementsByClassName('flip')[0]
        //       window.console.log(document.getElementsByClassName('flip'))
        //       let divScrollTop = testDiv.scrollTop;
        //       let divClientHeight = testDiv.clientHeight;
        //       let divScrollHeight = testDiv.scrollHeight;
        //       let cScrollTop = container.scrollTop;
        //       let cClientHeight = container.clientHeight;
        //       let cScrollHeight = container.scrollHeight;
        //       if(divScrollTop + divClientHeight < divScrollHeight && cScrollTop + cClientHeight < cScrollHeight){
        //         this.$store.commit('setTips',true)
        //       }else{
        //         this.$store.commit('setTips',false)
        //       }
        //     },1100)
        //   }
        // },
        methods: {
            // divScroll(){
            //   let testDiv = document.getElementsByClassName('answer-list')[document.getElementsByClassName('answer-list').length-1]
            //   let container = document.getElementsByClassName('flip')[0]
            //   window.console.log(document.getElementsByClassName('flip'))
            //   let divScrollTop = testDiv.scrollTop;
            //   let divClientHeight = testDiv.clientHeight;
            //   let divScrollHeight = testDiv.scrollHeight;
            //   let cScrollTop = container.scrollTop;
            //   let cClientHeight = container.clientHeight;
            //   let cScrollHeight = container.scrollHeight;
            //   window.console.log(cScrollTop + cClientHeight < cScrollHeight,'111111111111')
            //   if(divScrollTop + divClientHeight < divScrollHeight){
            //     this.$store.commit('setTips',true)
            //   }else{
            //     this.$store.commit('setTips',false)
            //   }
            // },
            onChange(e) {
                this.resultData.isAnswered = true
                this.resultData.answerList.forEach((el) => {
                    if (el.answerId == e.target.value) {
                        el.checked = true
                    } else {
                        el.checked = false
                    }
                })
                this.resultData.answerList.splice(0, 0)
                this.$emit('isSingleAnswered', true)
            },
        },
    }
</script>

<style lang="less" type="text/less" scoped>
    .single-question-content {
        height: 100%;
        overflow: hidden;

        .single-answer-list-li {
            position: relative;

            .tishi-wall {
                position: absolute;
                right: 0.5rem;
                top: 0.1rem;
            }
        }
    }
</style>
