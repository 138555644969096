<template>
      <div class="Tips">
        <img src="../../../assets/img/reasonList/jiantou.png" alt="">
      </div>
</template>

<script>
export default {
  name: "Tips"
}
</script>

<style scoped lang="less">
.Tips{
  position: absolute;
  bottom: .30rem;
  right: .10rem;
  z-index: 100;
  div{
    background-color: #ffa525;
    color: #fff;
  }
  img{
    width: .88rem;
    height: .58rem;
    animation: swing 6s .15s ease-in-out infinite;
  }
}

@keyframes swing {
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  50%,100% {
    transform: rotate(0deg);
  }
}

</style>