<template>
    <div class="multiple-question-content">
        <div class="question-and-answer">
            <div class="question-title">
                <div class="question-title-font">
                    <span>可多选</span>
                    <div class="sub-title">{{ questionIndex }}.{{ resultData.questionText }}</div>
                </div>
                <div v-if="resultData.remark" class="remark">{{ resultData.remark }}</div>
            </div>
            <div class="answer-list">
                <div class="answer-list-question">
                    <div v-for="(item, index) in resultData.answerList" :key="index">
                        <div class="multiple-answer-list-li" v-if="item.display == 'true'">
                            <div :class="item.checked ? 'answer-list-li-active' : 'answer-list-li'">
                                <a-checkbox
                                    :style="checkboxStyle"
                                    :value="item.answerId"
                                    :checked="item.checked"
                                    @change="onChange($event, item.mutuallyExclusiveRule, index)"
                                    :disabled="fanye"
                                >
                                    <span class="answerText">{{ item.answerText }}</span>
                                </a-checkbox>
                            </div>
                            <a-popover placement="right" v-if="item.remark">
                                <template slot="content">
                                    <div>{{ item.remark }}</div>
                                </template>
                                <div class="tishi-wall">?</div>
                            </a-popover>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MultipleQuestion',
        props: ['resultData', 'questionIndex', 'fanye'],
        data() {
            return {
                flag: true,
                value: [],
                checkboxStyle: {
                    display: 'block',
                    color: '#333333',
                },
            }
        },
        mounted() {
            this.initSeleted()
        },
        // watch:{
        //   fanye(){
        //     setTimeout(()=>{
        //       let testDiv = document.getElementsByClassName('answer-list')[document.getElementsByClassName('answer-list').length-1]
        //       let divScrollTop = testDiv.scrollTop;
        //       let divClientHeight = testDiv.clientHeight;
        //       let divScrollHeight = testDiv.scrollHeight;
        //       if(divScrollTop + divClientHeight < divScrollHeight){
        //         this.$store.commit('setTips',true)
        //       }else{
        //         this.$store.commit('setTips',false)
        //       }
        //     },1100)
        //   }
        // },
        methods: {
            // divScroll(){
            //   let testDiv = document.getElementsByClassName('answer-list')[document.getElementsByClassName('answer-list').length-1]
            //   let divScrollTop = testDiv.scrollTop;
            //   let divClientHeight = testDiv.clientHeight;
            //   let divScrollHeight = testDiv.scrollHeight;
            //   if(divScrollTop + divClientHeight < divScrollHeight){
            //     this.$store.commit('setTips',true)
            //   }else{
            //     this.$store.commit('setTips',false)
            //   }
            // },
            onChange(e, mutuallyExclusiveRule, index) {
                this.resultData.answerList[index].checked = e.target.checked
                if (mutuallyExclusiveRule && e.target.checked) {
                    if (mutuallyExclusiveRule.length > 0) {
                        //点击的是互斥选项，取消其他选择
                        this.resultData.answerList.forEach((item) => {
                            mutuallyExclusiveRule.forEach((el) => {
                                if (el == item.answerCode) {
                                    item.checked = false
                                }
                            })
                        })
                    }
                }
                this.resultData.answerList.splice(0, 0)
            },

            initSeleted() {
                let checkedArr = this.resultData.answerList.filter((el) => {
                    return el.checked
                })
                if (checkedArr.length == 0) {
                    this.resultData.answerList.forEach((el) => {
                        el.checked = el.initSelected == '1'
                    })
                }
            },
        },
    }
</script>

<style lang="less" type="text/less" scoped>
    .multiple-question-content {
        height: 100%;
        overflow: hidden;

        .multiple-answer-list-li {
            position: relative;

            .tishi-wall {
                position: absolute;
                right: 0.5rem;
                top: 0.1rem;
            }
        }
    }
</style>
