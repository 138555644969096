var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-question-content"},[_c('div',{staticClass:"question-and-answer"},[_c('div',{staticClass:"question-title"},[_c('div',{staticClass:"question-title-font"},[_c('span',[_vm._v("填空")]),_c('div',{staticClass:"sub-title"},[_vm._v(_vm._s(_vm.questionIndex)+"."+_vm._s(_vm.resultData.questionText))])]),(_vm.resultData.remark)?_c('div',{staticClass:"remark"},[_vm._v(_vm._s(_vm.resultData.remark))]):_vm._e()]),_c('div',{staticClass:"answer-list"},_vm._l((_vm.resultData.answerList),function(item,index){return _c('div',{key:index,staticClass:"answerList"},[(item.remark)?_c('a-popover',{attrs:{"placement":"right"}},[_c('template',{slot:"content"},[_c('div',[_vm._v(_vm._s(item.remark))])]),_c('div',{staticClass:"tishi-wall"},[_vm._v("?")])],2):_vm._e(),_c('div',{staticClass:"answer-list-li-title"},[_vm._v(" "+_vm._s(item.supplement)+" ")]),_c('div',{staticClass:"answer-list-li answer-list-li-input"},[(item.answerAttribute == '0')?_c('div',[_c('div',{staticStyle:{"display":"flex","height":"0.56rem"}},[(item.isRequired == 1)?_c('span',{staticClass:"must"},[_vm._v("*")]):_c('span',{staticClass:"must"}),_c('a-form',[_c('a-form-item',{attrs:{"validate-status":_vm.ruleArr[index].rules
                                            ? _vm.ruleArr[index].rules.validateStatus
                                            : '',"help":_vm.ruleArr[index].rules
                                            ? _vm.ruleArr[index].rules.errorMsg
                                            : ''}},[_c('a-input',{staticStyle:{"width":"380px","margin-right":"10px"},attrs:{"placeholder":item.placeholderText,"onkeyup":"value=value.replace(/[^\\d^\\.]+/g,'')","value":item.answerText},on:{"focus":function($event){return _vm.onFocus($event)},"change":function($event){return _vm.onNumberChange(
                                                $event,
                                                index,
                                                item.gte,
                                                item.lte,
                                                'int'
                                            )}}})],1)],1)],1)]):(item.answerAttribute == '1')?_c('div',[_c('div',{staticStyle:{"display":"flex","height":"0.56rem"}},[(item.isRequired == 1)?_c('span',{staticClass:"must"},[_vm._v("*")]):_c('span',{staticClass:"must"}),_c('el-date-picker',{attrs:{"type":"date","placeholder":"请选择日期","editable":false},on:{"change":function($event){return _vm.onDateChange($event, index)}},model:{value:(item.answerText),callback:function ($$v) {_vm.$set(item, "answerText", $$v)},expression:"item.answerText"}})],1)]):(item.answerAttribute == '2')?_c('div',[_c('div',{staticStyle:{"display":"flex","height":"0.56rem"}},[(item.isRequired == 1)?_c('span',{staticClass:"must"},[_vm._v("*")]):_c('span',{staticClass:"must"}),_c('a-textarea',{staticStyle:{"width":"380px","margin-right":"10px","resize":"none"},attrs:{"auto-size":"","placeholder":item.placeholderText},on:{"change":function($event){return _vm.onTextChange($event, index)}},model:{value:(item.answerText),callback:function ($$v) {_vm.$set(item, "answerText", $$v)},expression:"item.answerText"}})],1)]):(item.answerAttribute == '3')?_c('div',[_c('div',{staticStyle:{"display":"flex","height":"0.56rem"}},[(item.isRequired == 1)?_c('span',{staticClass:"must"},[_vm._v("*")]):_c('span',{staticClass:"must"}),_c('a-form',[_c('a-form-item',{attrs:{"validate-status":_vm.ruleArr[index].rules
                                            ? _vm.ruleArr[index].rules.validateStatus
                                            : '',"help":_vm.ruleArr[index].rules
                                            ? _vm.ruleArr[index].rules.errorMsg
                                            : ''}},[_c('a-input',{staticStyle:{"width":"380px","margin-right":"10px"},attrs:{"placeholder":item.placeholderText,"onkeyup":"value=value.replace(/[^\\d^\\.]+/g,'')","value":item.answerText},on:{"change":function($event){return _vm.onNumberChange(
                                                $event,
                                                index,
                                                item.gte,
                                                item.lte,
                                                'float'
                                            )}}})],1)],1)],1)]):(item.answerAttribute == '4')?_c('div',[_c('div',[(item.isRequired == 1)?_c('span',{staticClass:"must"},[_vm._v("*")]):_c('span',{staticClass:"must"}),_c('a-input',{staticStyle:{"width":"380px","margin-right":"10px"},attrs:{"placeholder":item.placeholderText},on:{"focus":function($event){return _vm.handleSearch(item, 'focus', index)},"change":function($event){return _vm.handleSearch(item)},"blur":function($event){return _vm.blurSelect(index)}},model:{value:(item.answerText),callback:function ($$v) {_vm.$set(item, "answerText", $$v)},expression:"item.answerText"}}),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(index == _vm.selectShowIndex),expression:"index == selectShowIndex"}],staticClass:"select_4"},[(_vm.reasonArr.length > 0)?_vm._l((_vm.reasonArr),function(el){return _c('li',{key:el.reason,on:{"click":function($event){return _vm.clickReason(el.reason, index)}}},[_vm._v(" "+_vm._s(el.reason)+" ")])}):_c('div',{staticStyle:{"padding":"0.2rem 0"}},[_c('a-empty',{attrs:{"description":"暂未搜索到相关案由"}})],1)],2)],1)]):_vm._e()])],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }