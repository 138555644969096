<template>
    <div class="content-wall">
        <div class="content" :class="diyiti ? 'show' : ''">
            <div class="question-content">
                <Label></Label>
                <Tips v-if="tips"></Tips>
                <div class="progress-box">
                    <a-progress
                        :stroke-color="{ from: '#1CD4BD', to: '#1CD4BD' }"
                        :percent="answeringProgress"
                        status="active"
                    />
                    <p> 本次答题将耗费您3-8分钟 </p>
                    <!--                    <img-->
                    <!--                        src="../../../assets/img/question/prograss-icon.png"-->
                    <!--                        class="prograss-icon"-->
                    <!--                        v-if="answeringProgress != 0"-->
                    <!--                        :style="{ left: answeringProgress + '%' }"-->
                    <!--                        alt=""-->
                    <!--                    />-->
                </div>
                <div class="container" @scroll="divScroll">
                    <div class="flip" :class="fanye ? 'on' : 'off'">
                        <div
                            v-for="(item, index) in groupQuestionsList"
                            :key="index"
                            class="ti-wall"
                        >
                            <single-question
                                :groupQuestionsList="groupQuestionsList"
                                :fanye="fanye"
                                v-if="item.answerType == '1'"
                                :resultData="item"
                                @isSingleAnswered="getIsSingleAnswered"
                                :questionIndex="firstIndex + index"
                            ></single-question>
                            <multiple-question
                                :groupQuestionsList="groupQuestionsList"
                                :fanye="fanye"
                                v-else-if="item.answerType == '2'"
                                :resultData="item"
                                :questionIndex="firstIndex + index"
                            ></multiple-question>
                            <input-question
                                :groupQuestionsList="groupQuestionsList"
                                :fanye="fanye"
                                v-else
                                :resultData="item"
                                :questionIndex="firstIndex + index"
                                :key="item.questionId"
                            ></input-question>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-btn">
                <a-button
                    :type="
                        questionCode == firstQuestionCode || firstIndex == 1 ? 'dashed' : 'danger'
                    "
                    :disabled="isDisabled || questionCode == firstQuestionCode || firstIndex == 1"
                    @click="getPreQuestion"
                    shape="round"
                    :block="true"
                >
                    回退
                </a-button>

                <a-button
                    type="primary"
                    @click="dealNextQuestion"
                    :disabled="isDisabled"
                    shape="round"
                    :block="true"
                >
                    继续
                </a-button>
            </div>
            <a-modal
                v-model="visible"
                on-ok="handleOk"
                @cancel="handleCancel"
                :closable="false"
                dialogClass="question-model"
                width="6.44rem"
            >
                <template slot="footer">
                    <div class="footer-main">
                        <div class="OFF" @click="handleCancel"> 返回 </div>
                        <div class="OK" @click="handleOk"
                            >生成{{ $route.query.type == 1 ? '意见书' : '文书' }}</div
                        >
                    </div>
                </template>
                <div class="model-main">
                    <div class="model-top">
                        <img
                            class="model-picture"
                            src="../../../assets/img/question/model.png"
                            alt=""
                        />
                        <div class="model-top-font">
                            <span>提示</span>
                            <img src="../../../assets/img/question/border.png" alt="" />
                        </div>
                    </div>
                    <div class="model-font">
                        <span
                            >请确认答案真实准确，生成{{
                                $route.query.type == 1 ? '意见书' : '文书'
                            }}后，</span
                        >
                        <span> 将无法返回修改答案。</span>
                    </div>
                </div>
            </a-modal>
        </div>
    </div>
</template>

<script>
    import InputQuestion from '@/components/PC/questionType/InputQuestion.vue'
    import Label from '@/components/PC/questionType/Label.vue'
    import Tips from '@/components/PC/questionType/Tips.vue'
    import * as Server from '@/api/server.js'
    import SingleQuestion from '@/components/PC/questionType/SingleQuestion'
    import MultipleQuestion from '@/components/PC/questionType/MultipleQuestion'
    import { apiList } from '@/api/apiList'

    export default {
        name: 'Questions',
        data() {
            return {
                visible: false,
                confirmLoading: false,
                resultId: '',
                moduleId: '',
                userQuestionId: '',
                result: {}, //查询接口返回数据
                groupQuestionsList: [], //将一页一题和一页多题整合到当前页答题数组
                newQuestionsData: {}, //点击下一题所需参数（带答案的对象回传）
                questionCode: '', //判断是否是第一题，是，禁用上一题按钮
                firstIndex: 1, //记录题号
                questionRecord: [],
                isDisabled: false,
                firstQuestionId: '',
                firstQuestionCode: '',
                fanye: false,
                diyiti: false,
                answeringProgress: 0,
            }
        },
        components: {
            MultipleQuestion,
            SingleQuestion,
            InputQuestion,
            Label,
            Tips,
        },
        mounted() {
            this.diyiti = true
            this.search()
            setTimeout(() => {
                let testDiv = document.getElementsByClassName('container')[0]
                let divScrollTop = testDiv.scrollTop
                let divClientHeight = testDiv.clientHeight
                let divScrollHeight = testDiv.scrollHeight
                if (divScrollTop + divClientHeight < divScrollHeight) {
                    this.$store.commit('setTips', true)
                } else {
                    this.$store.commit('setTips', false)
                }
            }, 300)
        },
        computed: {
            tips() {
                return this.$store.state.tips
            },
        },
        watch: {
            fanye() {
                setTimeout(() => {
                    document.getElementsByClassName('container')[0].scrollTo(0, 0)
                }, 550)
                setTimeout(() => {
                    let testDiv = document.getElementsByClassName('container')[0]
                    let divScrollTop = testDiv.scrollTop
                    let divClientHeight = testDiv.clientHeight
                    let divScrollHeight = testDiv.scrollHeight
                    if (divScrollTop + divClientHeight < divScrollHeight) {
                        this.$store.commit('setTips', true)
                    } else {
                        this.$store.commit('setTips', false)
                    }
                }, 1100)
            },
        },
        methods: {
            getIsSingleAnswered(data) {
                if (data && this.groupQuestionsList.length == 1) {
                    this.dealNextQuestion()
                }
            },
            divScroll() {
                let testDiv = document.getElementsByClassName('container')[0]
                let divScrollTop = testDiv.scrollTop
                let divClientHeight = testDiv.clientHeight
                let divScrollHeight = testDiv.scrollHeight
                if (divScrollTop + divClientHeight < divScrollHeight) {
                    this.$store.commit('setTips', true)
                } else {
                    this.$store.commit('setTips', false)
                }
            },
            //获取答卷
            async search() {
                //请求数据
                let data = {
                    method: 'GET',
                    url: apiList.createQuestionnaire + '/' + this.$route.query.moduleId,
                    config: {
                        data: {},
                    },
                    loading: true,
                }
                let dataSource = await Server.Axios(data)
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                let result = dataSource.data.returnData
                this.answeringProgress = Number(result.answeringProgress * 100).toFixed(0)
                this.result = result
                this.resultId = result.resultId
                this.moduleId = result.moduleId
                let questionList = []
                this.questionCode = result.questionCode
                this.firstQuestionCode = result.questionCode
                questionList.push({
                    answerList: result.answerList,
                    answerType: result.answerType,
                    moduleId: result.moduleId,
                    questionCode: result.questionCode,
                    questionId: result.questionId,
                    questionSort: result.questionSort,
                    questionText: result.questionText,
                    questionType: result.questionType,
                    remark: result.remark,
                    resultId: result.resultId,
                    userQuestionId: result.userQuestionId,
                })
                this.groupQuestionsList = [...questionList, ...result.groupQuestionsList]
                //当前页第一题的questionId
                this.firstQuestionId = this.groupQuestionsList[0].questionId
            },
            //处理跳转下一题所需参数
            dealNextQuestion() {
                if (this.fanye) return
                //校验必填项是否已填
                this.groupQuestionsList.forEach((el) => {
                    if (el.answerType == '1' || el.answerType == '2') {
                        el.answerList.forEach((item) => {
                            if (item.checked == true) {
                                el.isAnswered = true
                            }
                        })
                    } else if (el.answerType == '3') {
                        let flag = true
                        el.answerList.forEach((item) => {
                            if (item.answerText === '' && item.isRequired == '1') {
                                flag = false
                            } else if (item.answerTextError && item.isRequired == '1') {
                                flag = false
                            }
                        })
                        if (flag) {
                            el.isAnswered = true
                        }
                    }
                })
                let allAnswer = this.groupQuestionsList.some((el) => {
                    if (el.isAnswered != true) {
                        return true
                    }
                    return false
                })
                if (allAnswer) {
                    this.$message.warning('请答完本页所有题，再点击下一题')
                    return
                }
                this.isDisabled = true
                this.newQuestionsData = this.groupQuestionsList[0]
                this.newQuestionsData.userQuestionId = this.userQuestionId
                if (this.groupQuestionsList.length > 1) {
                    this.newQuestionsData.groupQuestionsList = this.groupQuestionsList.slice(
                        1,
                        this.groupQuestionsList.length
                    )
                }
                this.newQuestionsData.answerList.forEach((el) => {
                    if (el.answerAttribute == '0' || el.answerAttribute == '3') {
                        if (el.isRequired == '0' && el.answerText == '') {
                            el.answerText = 0
                        }
                    }
                })
                this.searchNextQuestion()
            },
            //跳转下一题
            async searchNextQuestion() {
                this.$store.commit('setSpin', false)
                this.fanye = true
                let timestamp = Date.parse(new Date())
                let LoadingTime = setTimeout(() => {
                    this.$store.commit('setSpin', true)
                }, 1200)
                //请求数据
                let data = {
                    method: 'post',
                    url: apiList.getNextQuestion,
                    config: {
                        data: this.newQuestionsData,
                    },
                    loading: false,
                }
                let dataSource = await Server.Axios(data)

                if (!dataSource) {
                    clearTimeout(LoadingTime)
                    this.isDisabled = false
                    this.fanye = false
                }

                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    this.isDisabled = false
                    return
                }

                let result = dataSource.data.returnData
                this.answeringProgress = Number(result.answeringProgress * 100).toFixed(0)
                this.result = result
                this.questionCode = result.questionCode
                this.userQuestionId = result.userQuestionId
                if (this.result.questionId == null) {
                    //没有题了，该生成意见书了
                    this.showModal()
                    this.userQuestionId = result.userQuestionId
                    clearTimeout(LoadingTime)
                    this.$store.commit('setSpin', false)
                    this.fanye = false
                    this.isDisabled = false
                    // console.log()
                    return
                }
                clearTimeout(LoadingTime)

                let TimeOut =
                    Date.parse(new Date()) - timestamp > 650
                        ? 400
                        : Date.parse(new Date()) - timestamp + 400

                setTimeout(() => {
                    this.fanye = false
                    setTimeout(() => {
                        this.isDisabled = false
                    }, 500)
                }, TimeOut)
                this.$store.commit('setSpin', false)
                setTimeout(() => {
                    this.firstIndex++
                    let questionList = []
                    questionList.push({
                        answerList: result.answerList,
                        answerType: result.answerType,
                        moduleId: result.moduleId,
                        questionCode: result.questionCode,
                        questionId: result.questionId,
                        questionSort: result.questionSort,
                        questionText: result.questionText,
                        questionType: result.questionType,
                        remark: result.remark,
                        resultId: result.resultId,
                        userQuestionId: result.userQuestionId,
                    })
                    if (result.groupQuestionsList.length > 0) {
                        this.groupQuestionsList = [...questionList, ...result.groupQuestionsList]
                    } else {
                        this.groupQuestionsList = questionList
                    }
                    //当前页第一题的questionId
                    this.groupQuestionsList.forEach((item) => {
                        if (item.answerType == 2) {
                            let flag = true
                            item.answerList.forEach((el) => {
                                if (el.checked) {
                                    flag = false
                                }
                            })
                            if (flag) {
                                item.answerList.forEach((el) => {
                                    if (el.initSelected == 1) {
                                        el.checked = true
                                    }
                                })
                            }
                        }
                    })

                    this.firstQuestionId = this.groupQuestionsList[0].questionId
                    window.scrollTo(0, 1)

                    this.getQuestionRecord('next')
                }, 500)
            },
            //获取答题记录
            async getQuestionRecord(item) {
                // //请求数据
                // let data = {
                //   method: 'get',
                //   url: 'q/front/getAllQuestions/' + this.resultId,
                //   loading: false
                // };
                // let dataSource = await Server.Axios(data);
                // this.questionRecord = dataSource.data.returnData;
                // if (item == 'next') {
                //   let temp = true;
                //   this.questionRecord.forEach((el, index) => {
                //     if (el.questionId == this.firstQuestionId) {
                //       this.firstIndex = index + 1;
                //       temp = false;
                //     }
                //   })
                //   //问题已达过，先上一题，然后下一题
                //   if (temp) {
                //     this.firstIndex = this.questionRecord.length + 1;
                //   }
                // } else if (item == 'pre') {
                //   this.questionRecord.forEach((el, index) => {
                //     if (el.questionId == this.firstQuestionId) {
                //       this.firstIndex = index + 1;
                //     }
                //   })
                // }
            },
            //跳转上一题
            async getPreQuestion() {
                this.isDisabled = true
                this.$store.commit('setSpin', false)
                this.fanye = true
                let timestamp = Date.parse(new Date())
                let LoadingTime = setTimeout(() => {
                    this.$store.commit('setSpin', true)
                }, 1200)
                //请求数据
                let data = {
                    method: 'post',
                    url: apiList.getPreQuestion,
                    config: {
                        data: {
                            resultId: this.resultId,
                            userQuestionId: this.userQuestionId,
                        },
                    },
                    loading: false,
                }
                let dataSource = await Server.Axios(data)
                if (!dataSource) {
                    clearTimeout(LoadingTime)
                    this.isDisabled = false
                    this.fanye = false
                }
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    this.isDisabled = false
                    return
                }
                clearTimeout(LoadingTime)
                let TimeOut =
                    Date.parse(new Date()) - timestamp > 650
                        ? 400
                        : Date.parse(new Date()) - timestamp + 400
                setTimeout(() => {
                    this.fanye = false
                    setTimeout(() => {
                        this.isDisabled = false
                    }, 500)
                }, TimeOut)

                this.$store.commit('setSpin', false)
                setTimeout(() => {
                    this.firstIndex--
                    let result = dataSource.data.returnData
                    this.result = result
                    this.answeringProgress = (result.answeringProgress * 100).toFixed(0)
                    this.questionCode = result.questionCode
                    this.resultId = result.resultId
                    this.userQuestionId = result.userQuestionId
                    let questionList = []
                    questionList.push({
                        answerList: result.answerList,
                        answerType: result.answerType,
                        moduleId: result.moduleId,
                        questionCode: result.questionCode,
                        questionId: result.questionId,
                        questionSort: result.questionSort,
                        questionText: result.questionText,
                        questionType: result.questionType,
                        remark: result.remark,
                        resultId: result.resultId,
                        userQuestionId: result.userQuestionId,
                    })
                    if (result.groupQuestionsList.length > 0) {
                        this.groupQuestionsList = [...questionList, ...result.groupQuestionsList]
                    } else {
                        this.groupQuestionsList = questionList
                    }
                    //当前页第一天的questionId
                    this.firstQuestionId = this.groupQuestionsList[0].questionId
                    window.scrollTo(0, 1)
                    this.getQuestionRecord('pre')
                }, 500)
            },
            // 显示弹框
            showModal() {
                this.visible = true
            },
            //生成意见书
            handleOk() {
                this.$router.push({
                    name: 'Result',
                    query: {
                        type: this.$route.query.type,
                        resultId: this.resultId,
                        moduleId: this.moduleId,
                    },
                })
            },
            handleCancel() {
                this.visible = false
                this.isDisabled = false
            },
        },
    }
</script>

<style lang="less" scoped>
    .content {
        width: 12.2rem;
        height: 88vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        /*justify-content: center;*/
        overflow: hidden;
    }

    .content-wall {
        height: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    .question-content {
        width: 100%;
        height: 81%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0.8rem 0.6rem 0.3rem;
        border-radius: 20px;
        background-color: #358cf3;
        position: relative;
        margin-top: 0.4rem;

        .temp-div {
            width: 90%;
            margin: 0 auto;
            min-height: 200px;
            border: 1px solid #ccc;
            text-align: left;
        }
    }

    .progress-box {
        width: 7rem;
        margin: -10px auto 30px;
        height: 20px;
        margin-left: 1.92rem;
        position: relative;
        p {
            text-align: center;
            color: #fff;
            letter-spacing: 2px;
            margin-top: 30px;
            font-size: 0.14rem;
        }
        .prograss-icon {
            position: absolute;
            top: 5px;
            transition: All 0.1s ease-in-out;
        }
    }

    .container {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }

    .container::-webkit-scrollbar {
        display: none;
    }

    .ti-wall {
        margin-bottom: 0.4rem;
    }

    .ti-wall:last-child {
        margin-bottom: 0px;
    }

    .bottom-btn {
        display: flex;
        position: relative;
        bottom: -0.15rem;

        .ant-btn {
            width: 2.6rem;
            height: 0.64rem;
            font-size: 0.24rem;
            margin: 0 0.2rem;
            letter-spacing: 0.03rem;
        }
    }

    .footer-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 0.74rem;

        .OK {
            width: 50%;
            text-align: center;
            height: 0.74rem;
            background-color: #358cf3;
            border-radius: 0 0 0.1rem 0;
            line-height: 0.74rem;
            color: #fff;
            font-size: 0.24rem;
            letter-spacing: 0.02rem;
            cursor: pointer;
        }

        .OFF {
            width: 50%;
            text-align: center;
            height: 0.74rem;
            background-color: #eaf3fe;
            border-radius: 0 0 0 0.1rem;
            line-height: 0.74rem;
            color: #358cf3;
            font-size: 0.24rem;
            letter-spacing: 0.02rem;
            cursor: pointer;
        }
    }

    .model-main {
        display: flex;
        flex-direction: column;

        .model-top {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .model-picture {
                width: 1.77rem;
                height: 1.22rem;
                margin-top: 0.5rem;
            }

            .model-top-font {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-top: 0.29rem;

                span {
                    font-size: 0.3rem;
                    font-weight: bold;
                    color: #333333;
                    line-height: 0.26rem;
                }

                img {
                    width: 1.33rem;
                    height: 0.24rem;
                    margin-top: -0.1rem;
                }
            }
        }

        .model-font {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 0.2rem;

            span {
                font-size: 0.24rem;
                color: #555555;
                line-height: 0.36rem;
            }
        }
    }
</style>
