<template>
    <div class="input-question-content">
        <div class="question-and-answer">
            <div class="question-title">
                <div class="question-title-font">
                    <span>填空</span>
                    <div class="sub-title">{{ questionIndex }}.{{ resultData.questionText }}</div>
                </div>
                <div v-if="resultData.remark" class="remark">{{ resultData.remark }}</div>
            </div>
            <div class="answer-list">
                <div v-for="(item, index) in resultData.answerList" :key="index" class="answerList">
                    <a-popover placement="right" v-if="item.remark">
                        <template slot="content">
                            <div>{{ item.remark }}</div>
                        </template>
                        <div class="tishi-wall">?</div>
                    </a-popover>
                    <!--数字，number-->
                    <div class="answer-list-li-title">
                        {{ item.supplement }}
                    </div>
                    <div class="answer-list-li answer-list-li-input">
                        <!--                        额外属性 0:整数 1：日期 2：文本 3：小数-->
                        <div v-if="item.answerAttribute == '0'">
                            <div style="display: flex; height: 0.56rem">
                                <span v-if="item.isRequired == 1" class="must">*</span>
                                <span v-else class="must"></span>
                                <a-form>
                                    <a-form-item
                                        :validate-status="
                                            ruleArr[index].rules
                                                ? ruleArr[index].rules.validateStatus
                                                : ''
                                        "
                                        :help="
                                            ruleArr[index].rules
                                                ? ruleArr[index].rules.errorMsg
                                                : ''
                                        "
                                    >
                                        <a-input
                                            @focus="onFocus($event)"
                                            style="width: 380px; margin-right: 10px"
                                            :placeholder="item.placeholderText"
                                            onkeyup="value=value.replace(/[^\d^\.]+/g,'')"
                                            :value="item.answerText"
                                            @change="
                                                onNumberChange(
                                                    $event,
                                                    index,
                                                    item.gte,
                                                    item.lte,
                                                    'int'
                                                )
                                            "
                                        />
                                    </a-form-item>
                                </a-form>
                            </div>
                        </div>
                        <div v-else-if="item.answerAttribute == '1'">
                            <div style="display: flex; height: 0.56rem">
                                <span v-if="item.isRequired == 1" class="must">*</span>
                                <span v-else class="must"></span>
                                <!-- <a-date-picker
                                    style="width: 200px"
                                    placeholder="请选择日期"
                                    :inputReadOnly="true"
                                    :locale="locale"
                                    :value="
                                        item.answerText == ''
                                            ? null
                                            : moment(item.answerText, dateFormat)
                                    "
                                    @change="
                                        (value, dateString) =>
                                            onDateChange(value, dateString, index)
                                    "
                                /> -->
                                <el-date-picker
                                    v-model="item.answerText"
                                    @change="onDateChange($event, index)"
                                    type="date"
                                    placeholder="请选择日期"
                                    :editable="false"
                                >
                                </el-date-picker>
                            </div>
                        </div>
                        <div v-else-if="item.answerAttribute == '2'">
                            <div style="display: flex; height: 0.56rem">
                                <span v-if="item.isRequired == 1" class="must">*</span>
                                <span v-else class="must"></span>
                                <a-textarea
                                    v-model="item.answerText"
                                    @change="onTextChange($event, index)"
                                    auto-size
                                    style="width: 380px; margin-right: 10px; resize: none"
                                    :placeholder="item.placeholderText"
                                />
                            </div>
                        </div>
                        <div v-else-if="item.answerAttribute == '3'">
                            <div style="display: flex; height: 0.56rem">
                                <span v-if="item.isRequired == 1" class="must">*</span>
                                <span v-else class="must"></span>
                                <a-form>
                                    <a-form-item
                                        :validate-status="
                                            ruleArr[index].rules
                                                ? ruleArr[index].rules.validateStatus
                                                : ''
                                        "
                                        :help="
                                            ruleArr[index].rules
                                                ? ruleArr[index].rules.errorMsg
                                                : ''
                                        "
                                    >
                                        <a-input
                                            style="width: 380px; margin-right: 10px"
                                            :placeholder="item.placeholderText"
                                            onkeyup="value=value.replace(/[^\d^\.]+/g,'')"
                                            :value="item.answerText"
                                            @change="
                                                onNumberChange(
                                                    $event,
                                                    index,
                                                    item.gte,
                                                    item.lte,
                                                    'float'
                                                )
                                            "
                                        />
                                    </a-form-item>
                                </a-form>
                            </div>
                        </div>
                        <div v-else-if="item.answerAttribute == '4'">
                            <div>
                                <span v-if="item.isRequired == 1" class="must">*</span>
                                <span v-else class="must"></span>
                                <a-input
                                    style="width: 380px; margin-right: 10px"
                                    @focus="handleSearch(item, 'focus', index)"
                                    @change="handleSearch(item)"
                                    @blur="blurSelect(index)"
                                    :placeholder="item.placeholderText"
                                    v-model="item.answerText"
                                />
                                <ul class="select_4" v-show="index == selectShowIndex">
                                    <template v-if="reasonArr.length > 0">
                                        <li
                                            v-for="el in reasonArr"
                                            :key="el.reason"
                                            @click="clickReason(el.reason, index)"
                                        >
                                            {{ el.reason }}
                                        </li>
                                    </template>
                                    <div v-else style="padding: 0.2rem 0">
                                        <a-empty description="暂未搜索到相关案由" />
                                    </div>
                                </ul>
                                <!-- <a-select
                                    show-search
                                    @search="handleSearch($event, item.reasonParentId)"
                                    :value="item.answerText"
                                    @change="handleChange($event, index)"
                                    :dropdownMenuStyle="{
                                        display: reasonArr.length > 0 ? 'block' : 'none',
                                    }"
                                    :defaultOpen="true"
                                    style="width: 590px"
                                    :showArrow="false"
                                    dropdownClassName="reason-select"
                                >
                                    <a-select-option
                                        v-for="el in reasonArr"
                                        :value="el.reason"
                                        :key="el.reason"
                                    >
                                        {{ el.reason }}
                                    </a-select-option>
                                </a-select> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import locale from 'ant-design-vue/lib/date-picker/locale/zh_CN'
    import moment from 'moment'
    import 'moment/locale/zh-cn'
    import Vue from 'vue'
    import * as Server from '@/api/server.js'
    import { DatePicker } from 'element-ui'
    import { apiList } from '../../../api/apiList'
    Vue.use(DatePicker)
    export default {
        name: 'InputQuestion',
        props: ['resultData', 'questionIndex', 'fanye'],
        data() {
            return {
                ruleArr: [],
                dateFormat: 'YYYY-MM-DD',
                moment,
                locale,
                reasonArr: [],
                oldValue: null,
                selectShowIndex: -1,
            }
        },
        // mounted() {
        //   this.divScroll()
        // },
        // watch: {
        //   fanye() {
        //     setTimeout(() => {
        //       let testDiv = document.getElementsByClassName('answer-list')[document.getElementsByClassName('answer-list').length - 1]
        //       let divScrollTop = testDiv.scrollTop;
        //       let divClientHeight = testDiv.clientHeight;
        //       let divScrollHeight = testDiv.scrollHeight;
        //       if (divScrollTop + divClientHeight < divScrollHeight) {
        //         this.$store.commit('setTips', true)
        //       } else {
        //         this.$store.commit('setTips', false)
        //       }
        //     }, 1100)
        //   }
        // },

        created() {
            let length = this.resultData.answerList.length
            for (let i = 0; i < length; i++) {
                this.ruleArr.push({
                    index: i,
                })
            }
        },

        methods: {
            // divScroll() {
            //   let testDiv = document.getElementsByClassName('answer-list')[document.getElementsByClassName('answer-list').length - 1]
            //   let divScrollTop = testDiv.scrollTop;
            //   let divClientHeight = testDiv.clientHeight;
            //   let divScrollHeight = testDiv.scrollHeight;
            //   if (divScrollTop + divClientHeight < divScrollHeight) {
            //     this.$store.commit('setTips', true)
            //   } else {
            //     this.$store.commit('setTips', false)
            //   }
            // },
            //判断这一组填空题是否都已经填写
            getListIsAnswered() {
                let flag = true
                this.resultData.answerList.forEach((el) => {
                    if (el.answerText == '') {
                        flag = false
                        this.resultData.isAnswered = false
                        return
                    }
                })
                if (flag) {
                    this.resultData.isAnswered = true
                }
            },
            //数字
            onNumberChange(e, index, min, max, flag) {
                let rules = this.validatePrimeNumber(e.target.value, index, min, max, flag)
                this.ruleArr[index].rules = rules
                this.resultData.answerList[index].answerText = e.target.value
            },
            validatePrimeNumber(number, index, min, max, flag) {
                if (flag == 'int' && String(number).indexOf('.') != -1) {
                    this.resultData.answerList[index].answerTextError = true
                    return {
                        validateStatus: 'error',
                        errorMsg: '应填写整数',
                    }
                } else if (min && Number(number) < Number(min)) {
                    this.resultData.answerList[index].answerTextError = true
                    return {
                        validateStatus: 'error',
                        errorMsg: '最小值为：' + min,
                    }
                } else if (max && Number(number) > Number(max)) {
                    this.resultData.answerList[index].answerTextError = true
                    return {
                        validateStatus: 'error',
                        errorMsg: '最大值为：' + max,
                    }
                } else {
                    this.resultData.answerList[index].answerTextError = false
                    return {
                        validateStatus: 'success',
                        errorMsg: null,
                    }
                }
            },
            //日期
            // onDateChange(value, dateString, index) {
            //     console.log(value, dateString, index)
            //     this.resultData.answerList[index].answerText = dateString
            // },
            onDateChange(event, index) {
                this.resultData.answerList[index].answerText = moment(event).format('YYYY-MM-DD')
            },
            //文本
            onTextChange(e, index) {
                this.resultData.answerList[index].answerText = e.target.value
            },
            clickReason(e, index) {
                this.resultData.answerList[index].answerText = e
                this.oldValue = e
            },
            handleSearch(item, type, index) {
                if (type == 'focus') {
                    this.oldValue = item.answerText
                }

                this.selectReason(item.answerText, item.reasonParentId, type, index)
            },
            blurSelect(index) {
                setTimeout(() => {
                    if (this.resultData.answerList[index].answerText !== this.oldValue) {
                        this.resultData.answerList[index].answerText = this.oldValue
                    }

                    this.reasonArr = []
                    this.oldValue = null
                    this.selectShowIndex = -1
                }, 300)
            },
            //获取案由
            async selectReason(value, reasonParentId, type, index) {
                //请求数据
                let data = {
                    method: 'POST',
                    url: apiList.selectReason,
                    config: {
                        data: {
                            parentId: reasonParentId,
                            reason: value,
                        },
                    },
                    loading: true,
                }
                let dataSource = await Server.Axios(data)
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                this.reasonArr = dataSource.data.returnData
                if (type == 'focus') {
                    this.selectShowIndex = index
                }
            },
        },
    }
</script>

<style lang="less" type="text/less" scoped>
    /deep/ .ant-form-item-control {
        line-height: 0.4rem;
    }
    .must {
        color: #fff;
        float: left;
        height: 0.56rem;
        display: flex;
        align-items: center;
    }

    .answerList {
        display: flex;
        align-items: center;
        /deep/.el-input__inner {
            height: 0.56rem;
            line-height: 0.56rem;
            background-color: rgba(0, 0, 0, 0);
            border: none;
            color: #fff;
            width: 6rem !important;
        }
        /deep/.el-input__prefix {
            display: none;
        }
    }

    .answer-list-li-title {
        width: 1.7rem;
        font-size: 0.2rem;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        margin-right: 0.2rem;
    }

    .answer-list-li-input {
        width: 6.64rem !important;
        .reason-div {
            background: #358cf3;
            width: 91%;
            height: 100px;
            position: absolute;
            z-index: 999;
            border: 1px solid #69acf9;
            top: 57px;
            color: #fff;
            padding: 0;
            display: none;
            li {
                border-bottom: 1px solid #69acf9;
                padding: 10px;
            }
        }
    }

    .select_4 {
        position: absolute;
        z-index: 100;
        max-height: 3.6rem;
        width: 6rem;
        background: #358cf3;
        border: 1px solid rgba(255, 255, 255, 0.5);
        padding: 0px;
        box-shadow: 1px;
        overflow-y: auto;
        top: 0.58rem;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
        &::-webkit-scrollbar {
            width: 3px;
            background-color: #358cf3;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #fff;
        }
        li {
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            padding: 0.1rem;
            color: #fff;
            cursor: pointer;
        }
        li:last-child {
            border-bottom: none;
        }

        /deep/.ant-empty-description {
            color: #fff;
        }

        /deep/.ant-empty-image {
            height: 1rem;
        }
    }
</style>
